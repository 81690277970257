<template lang="pug">
v-card
  .write-off-table__row
    .write-off-table__row-head
      .write-off-table__row-head-cell
        div(v-if="writeOff.type == 'production'")
          p.production
        div(v-else)
          p(:class="writeOff.reason")
      .write-off-table__row-head-cell.break-word {{ writeOff.ingredient_name }}
      .write-off-table__row-head-cell {{ writeOff.weight }} {{ 'units.kg' | translate }}
      .write-off-table__row-head-cell {{ writeOff.cost }}
      .write-off-table__row-head-cell {{ writeOff.ration_name }}
      .write-off-table__row-head-cell {{ writeOff.recipe_name }}
      .write-off-table__row-head-cell {{ writeOff.created_at | date }}
        v-info(dark).table__button.table__button--gap-right
            p.table__tooltip {{ writeOff.reason_description || 'messages.no_note' | translate }}

        v-btn(
            icon
            small
            @click="showHistory")
            v-chevron(:value="toggled")

    .write-off-table__row-body(v-if="toggled")
      table.table.table--small.table--no-background.table--cell-overflow-hidden
        thead.table__header
          tr.table__row.table__row--header
            th.table__cell {{ 'pages.storage.accounting_types.title' | translate }}
            th.table__cell.break-word {{ 'base.name' | translate }}
            th.table__cell {{ 'pages.storage.amount_write_off' | translate }} [{{ 'units.kg' | translate }}]
            th.table__cell {{ 'pages.storage.price_write_off' | translate }}
            th.table__cell {{ 'base.ration' | translate }}
            th.table__cell {{ 'base.recipe' | translate }}
            th.table__cell {{ 'base.date' | translate }}
        tbody.table__body
          tr.table__row.table__row--body.table__row--no-hover(v-if="writeOff.histories && writeOff.histories.length === 0")
            td.table__cell.text-center(colspan="6") {{ 'base.no_data' | translate}}
          tr.table__row.table__row--body.table__row--no-hover(v-for="(item, index) in writeOff.histories" :key="index")
            td.table__cell
              div(v-if="item.type == 'production'")
                p.production
              div(v-else)
                p(:class="item.reason")
            td.table__cell.break-word {{ item.ingredient_name }}
            td.table__cell {{ item.weight }} {{ 'units.kg' | translate }}
            td.table__cell {{ item.cost }}
            td.table__cell {{ item.ration_name }}
            td.table__cell {{ item.recipe_name }}
            td.table__cell.overflov-visible {{ item.created_at | date }}
              v-info(dark).table__button.table__button--gap-right
                p.table__tooltip {{ item.reason_description || 'messages.no_note' | translate }}

        tfoot.table__footer(
          v-if="!loading && showPagination")
          tr.table__row.table__row--footer
            td.table__cell(colspan="7")
              .table__pagination
                v-paginate(
                  v-model="page"
                  :page-count="pageCount"
                  :click-handler="handler")

</template>

<script>
  import { mapActions, mapGetters } from 'vuex'
  import IconGear from '@/components/icons/IconGear'
  import paginationMixin from '@/mixins/pagination.mixin'

  const PAGE_SIZE = 10

  export default {
    name: 'ExpenseTableRow',

    mixins: [paginationMixin],

    components: {
      IconGear
    },

    data: () => ({
      toggled: false
    }),

    props: {
      writeOff: {
        type: Object,
        required: true
      }
    },

    computed: {
      ...mapGetters([
        'writeOffsHistory',
        'writeOffsFilters'
      ]),

      filters () {
        return this.writeOffsFilters
      }
    },

    methods: {
      ...mapActions([
        'fetchWriteOffsHistory'
      ]),

      async showHistory () {
        this.toggled = !this.toggled
        if (this.toggled) {
            const ingredientID = this.writeOff.ingredient_id
            const params = {
              page: this.page,
              ...(this.filters || {})
            }
            await this.setupPagination(() => this.fetchWriteOffsHistory({ ingredientID, params }))
            this.page = 1
        }
      },

      async handler (page) {
        this.loading = true
        const params = {
          page: this.page,
          ...(this.filters || {})
        }
        const ingredientID = this.writeOff.ingredient_id
        const { data, total } = await this.$store.dispatch('fetchWriteOffsHistory', { ingredientID, params })
        this.items = data
        this.pageCount = Math.floor(total / PAGE_SIZE) + (total % PAGE_SIZE > 0 ? 1 : 0)
        this.loading = false
      }
    }

  }
</script>

<style lang="scss" scoped>
  @import '@/sass/abstracts/_variables.scss';

  .break-word {
    word-break: break-word;
  }

  .overflov-visible {
    overflow: visible !important;
  }

  .write-off-table__row {

    &-head {
      position: relative;
      display: flex;
      gap: 8px;
      align-items: center;
      gap: 8px;
      padding: .8rem 1.5rem;

      &-cell {
        width: 17%;

        &:nth-child(1) {
          width: 50px;
          min-width: 50px;
        }

        &:nth-child(5) {
          width: 30%;
        }

        &:nth-child(6) {
          width: 30%;
        }

        &:nth-child(7) {
          width: 30%;
        }
      }
    }

    &-body {
      border-top: .6rem solid $color-gray-light-2;
    }

    .table__row--body:last-child {
      border-bottom: none;
    }

    .planning-table__icon {
      position: absolute;
      right: 1rem;
      top: 50%;
      transform: translateY(-50%);
      display: flex;
      align-items: center;
    }

    .text-center {
      text-align: center;
    }

    .production {
      color: blue;
    }

    .write-off {
      color: red;
    }

    .v-info {
      margin-left: 1rem;
    }

    .production {
      width: 24px;
      height: 24px;
      background: url('../../../../../assets/icons/production.png') no-repeat center center;
    }

    .manual {
      width: 24px;
      height: 15px;
      background: url('../../../../../assets/icons/traced.png') no-repeat center center;
    }

    .write-off {
      width: 24px;
      height: 15px;
      background: url('../../../../../assets/icons/expense.png') no-repeat center center;
    }

  }
</style>
